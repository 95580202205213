import {
  MultiSearchProps,
  PropertyPlaceProps,
  Tab,
} from "@kidslivesafe/sc-react/lib/molecules/MultiSearch/MultiSearch";
import React from "react";
import { usePhoneReportDispatch } from "./phoneReportHook";
import { useSocialSearchDispatch } from "./socialSearchHook";
import {
  SearchPeopleParams,
  usePeopleSearchDispatch,
} from "./peopleReportHook";
import { selectBrandInfo } from "../features/brand/brandSlice";
import { useAppSelector } from "./hooks";
import { usePropertySearchDispatch } from "./propertyReportHook";
import { selectReinitialize } from "../features/multisearch/multisearchSlice";
import { useAutoReportDispatch } from "./autoReportHook";
import {
  useNeighborhoodReportDispatch,
  useNeighborhoodSearchDispatch,
} from "./neighborhoodReportHook";
import { NeighborhoodSearchType } from "@kidslivesafe/sc-react/lib/molecules/MultiSearch/NeighborhoodInputs.MultiSearch";
import {
  selectNeighborhoodReportStatus,
  selectPropertyReportStatus,
} from "../features/reports/reportsSlice";

interface MultiSearchPropOptions extends Partial<MultiSearchProps> {
  handleCancelSearchMiddleware?: () => void;
  handlePeopleSearchMiddleware?: () => void;
  setPeopleSearchValue?: SearchPeopleParams;
  handlePropertySearchMiddleware?: () => void;
  handleNeighborhoodSearchMiddleware?: () => void;
  handleNeighborhoodNameSearchMiddleware?: () => void;
  handlePhoneSearchMiddleware?: () => void;
  handleSocialSearchMiddleware?: () => void;
  handleAutoSearchMiddleware?: () => void;
  setMultiSearchError?: string;
  resetInput?: boolean | undefined;
  neighborhoodModifactions?: {
    showNeighborhoodRadioInput?: boolean;
    setDefaultNeighborhoodSearchType?: NeighborhoodSearchType;
  };
}

export const useMultiSearchProps = (
  modifiers: MultiSearchPropOptions,
  idPrefix: string,
): MultiSearchProps => {
  const [peopleFirstName, setPeopleFirstName] = React.useState<string>("");
  const [peopleFirstNameError, setPeopleFirstNameError] =
    React.useState<boolean>(false);
  const [peopleLastName, setPeopleLastName] = React.useState<string>("");
  const [peopleLastNameError, setPeopleLastNameError] =
    React.useState<boolean>(false);
  const [peopleCity, setPeopleCity] = React.useState<string>("");
  const [peopleCityError, setPeopleCityError] = React.useState<boolean>(false);
  const [peopleState, setPeopleState] = React.useState<string>("");
  const [peopleStateError, setPeopleStateError] =
    React.useState<boolean>(false);
  const [peopleAge, setPeopleAge] = React.useState<string>("");
  const [peopleAgeError, setPeopleAgeError] = React.useState<boolean>(false);

  const [propertyAddress, setPropertyAddress] =
    React.useState<PropertyPlaceProps>({});
  const [propertyAddressError, setPropertyAddressError] =
    React.useState<boolean>(false);
  const [phoneInputValue, setPhoneInputValue] = React.useState<string>("");
  const [phoneInputError, setPhoneInputError] = React.useState<boolean>(false);
  const [socialInputValue, setSocialInputValue] = React.useState<string>("");
  const [socialInputError, setSocialInputError] =
    React.useState<boolean>(false);
  const [autoInputValue, setAutoInputValue] = React.useState<string>("");
  const [autoInputError, setAutoInputError] = React.useState<boolean>(false);

  const [neighborhoodAddress, setNeighborhoodAddress] =
    React.useState<PropertyPlaceProps>({});
  const [neighborhoodAddressError, setNeighborhoodAddressError] =
    React.useState<boolean>(false);

  const [neighborhoodName, setNeighborhoodName] = React.useState<string>("");
  const [neighborhoodNameError, setNeighborhoodNameError] =
    React.useState<boolean>(false);

  const [neighborhoodSearchType, setNeighborhoodSearchType] =
    React.useState<NeighborhoodSearchType>("locationSearch");

  const [multiSearchError, setMultiSearchError] = React.useState<
    string | undefined
  >(undefined);
  const [tab, setTab] = React.useState<Tab | undefined>(undefined);

  const searchPeople = usePeopleSearchDispatch();
  const searchSocial = useSocialSearchDispatch();
  const searchPhone = usePhoneReportDispatch();
  const searchProperty = usePropertySearchDispatch();
  const searchNeighborhood = useNeighborhoodReportDispatch();
  const searchNameNeighborhood = useNeighborhoodSearchDispatch();
  const searchAuto = useAutoReportDispatch();
  const brandInfo = useAppSelector(selectBrandInfo);
  const reinitialize = useAppSelector(selectReinitialize);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const neighborhoodReportStatus = useAppSelector(
    selectNeighborhoodReportStatus,
  );

  React.useEffect(() => {
    if (modifiers.neighborhoodModifactions?.setDefaultNeighborhoodSearchType) {
      setNeighborhoodSearchType(
        modifiers.neighborhoodModifactions.setDefaultNeighborhoodSearchType,
      );
    }
  }, [modifiers.neighborhoodModifactions?.setDefaultNeighborhoodSearchType]);

  React.useEffect(() => {
    if (modifiers.setPeopleSearchValue) {
      const { firstName, lastName, city, state, minAge, maxAge } =
        modifiers.setPeopleSearchValue;
      setPeopleFirstName(firstName || "");
      setPeopleLastName(lastName || "");
      setPeopleCity(city || "");
      setPeopleState(state ? state : "");
      setPeopleAge(
        minAge && maxAge && minAge === "All Ages"
          ? "All Ages"
          : minAge && maxAge
            ? `${minAge}-${maxAge}`
            : "",
      );
    }
    if (modifiers.setMultiSearchError?.startsWith("City not found.")) {
      setPeopleCityError(true);
    } else {
      setPeopleCityError(false);
    }
  }, [
    modifiers.setPeopleSearchValue,
    modifiers.setMultiSearchError,
    reinitialize,
  ]);

  const handleSearchPeople = async () => {
    let error: boolean = false;

    if (peopleFirstName.length < 2) {
      setMultiSearchError("First name must be at least 2 characters");
      setPeopleFirstNameError(true);
      error = true;
    }
    if (peopleLastName.length < 2) {
      setMultiSearchError("Last name must be at least 2 characters");
      setPeopleLastNameError(true);
      error = true;
    }

    if (error) {
      return;
    }

    try {
      searchPeople({
        firstName: peopleFirstName,
        lastName: peopleLastName,
        state: peopleState,
        city: peopleCity,
        minAge:
          peopleAge !== "All Ages"
            ? parseInt(peopleAge.split("-")[0])
            : "All Ages",
        maxAge:
          peopleAge !== "All Ages"
            ? parseInt(peopleAge.split("-")[1])
            : "All Ages",
      });
      modifiers.handlePeopleSearchMiddleware &&
        modifiers.handlePeopleSearchMiddleware();
      modifiers.resetInput && resetInputState();
    } catch (error) {
      console.log("searchPeople error", error);
    }
  };

  const handleSearchSocial = async () => {
    try {
      searchSocial(socialInputValue);
      modifiers.handleSocialSearchMiddleware &&
        modifiers.handleSocialSearchMiddleware();
      modifiers.resetInput && resetInputState();
    } catch (error) {
      console.log("searchSocial error", error);
    }
  };

  const handleSearchPhone = async () => {
    try {
      searchPhone(phoneInputValue);
      modifiers.handlePhoneSearchMiddleware &&
        modifiers.handlePhoneSearchMiddleware();
      modifiers.resetInput && resetInputState();
    } catch (error) {
      console.log("phoneSearch error", error);
    }
  };

  const handleSearchProperty = async () => {
    try {
      if (
        propertyAddress.formatted_address &&
        propertyReportStatus !== "loading"
      ) {
        searchProperty(propertyAddress);
        modifiers.handlePropertySearchMiddleware &&
          modifiers.handlePropertySearchMiddleware();
        modifiers.resetInput && resetInputState();
      }
    } catch (error) {
      console.log("propertySearch error", error);
    }
  };

  const handleSearchNeighborhood = async () => {
    try {
      if (!neighborhoodAddress.formatted_address) {
        setNeighborhoodAddressError(true);
        setMultiSearchError("Please enter a location to search.");
        return;
      }

      if (neighborhoodReportStatus !== "loading") {
        searchNeighborhood({
          addressSearch: {
            address: [
              neighborhoodAddress.streetNumber,
              neighborhoodAddress.streetName,
            ].join(" "),
            city: neighborhoodAddress.city,
            state: neighborhoodAddress.state,
            zipCode: neighborhoodAddress.zip,
            addressLine2: neighborhoodAddress.subPremise,
          },
          searchMethod: "address",
        });
        modifiers.handleNeighborhoodSearchMiddleware &&
          modifiers.handleNeighborhoodSearchMiddleware();
        modifiers.resetInput && resetInputState();
      }
    } catch (error) {
      console.log("neighborhoodSearch error", error);
    }
  };

  const handleNeighborhoodNameSearch = async () => {
    try {
      if (!neighborhoodName) {
        setNeighborhoodNameError(true);
        setMultiSearchError("Please enter a name to search.");
        return;
      }

      const nameArray = neighborhoodName.split(" ");
      if (nameArray.length < 2) {
        setNeighborhoodNameError(true);
        setMultiSearchError("Please enter a first and last name to search.");
        return;
      }

      let firstName = nameArray.shift() || "";
      let lastName = nameArray.join(" ");

      if (firstName.length < 2 || lastName.length < 2) {
        setNeighborhoodNameError(true);
        setMultiSearchError("Please enter a first and last name to search.");
        return;
      }

      setNeighborhoodNameError(false);
      searchNameNeighborhood({
        firstName,
        lastName,
      });

      modifiers.handleNeighborhoodNameSearchMiddleware &&
        modifiers.handleNeighborhoodNameSearchMiddleware();
      modifiers.resetInput && resetInputState();
    } catch (error) {
      console.log("neighborhoodNameSearch error", error);
    }
  };

  const handleSearchAuto = async () => {
    try {
      searchAuto({ vin: autoInputValue });
      modifiers.handleAutoSearchMiddleware &&
        modifiers.handleAutoSearchMiddleware();
      modifiers.resetInput && resetInputState();
    } catch (error) {
      console.log("autoSearch error", error);
    }
  };

  const handleCancelSearch = () => {
    modifiers.handleCancelSearchMiddleware &&
      modifiers.handleCancelSearchMiddleware();
    resetInputState();
  };

  const resetInputState = () => {
    setPeopleFirstName("");
    setPeopleFirstNameError(false);
    setPeopleLastName("");
    setPeopleLastNameError(false);
    setPeopleCity("");
    setPeopleCityError(false);
    setPeopleState("");
    setPeopleStateError(false);
    setPeopleAge("");
    setPeopleAgeError(false);
    setPropertyAddress({});
    setPropertyAddressError(false);
    setNeighborhoodAddress({});
    setNeighborhoodAddressError(false);
    setPhoneInputValue("");
    setPhoneInputError(false);
    setSocialInputValue("");
    setSocialInputError(false);
    setAutoInputValue("");
    setAutoInputError(false);
    setMultiSearchError(undefined);
  };

  return {
    termsOfUseLink: `${brandInfo.homeUrl}/help-center/terms`,
    brandName: brandInfo.name,
    tabs: ["people", "property", "phone", "social"],
    openTab: tab,
    handleSetTab: setTab,
    handleCancelSearch: handleCancelSearch,
    error: multiSearchError,
    handleError: setMultiSearchError,
    people: {
      firstName: {
        id: `${idPrefix ? `${idPrefix}_` : ""}first-name`,
        value: peopleFirstName,
        handleChange: setPeopleFirstName,
        error: peopleFirstNameError,
        handleError: setPeopleFirstNameError,
      },
      lastName: {
        id: `${idPrefix ? `${idPrefix}_` : ""}last-name`,
        value: peopleLastName,
        handleChange: setPeopleLastName,
        error: peopleLastNameError,
        handleError: setPeopleLastNameError,
      },
      city: {
        id: `${idPrefix ? `${idPrefix}_` : ""}city`,
        value: peopleCity,
        handleChange: setPeopleCity,
        error: peopleCityError,
        handleError: setPeopleCityError,
      },
      state: {
        id: `${idPrefix ? `${idPrefix}_` : ""}state`,
        value: peopleState,
        handleChange: setPeopleState,
        error: peopleStateError,
        handleError: setPeopleStateError,
        options: [
          { label: "All States", value: "All States" },
          { label: "AK", value: "AK" },
          { label: "AL", value: "AL" },
          { label: "AR", value: "AR" },
          { label: "AZ", value: "AZ" },
          { label: "CA", value: "CA" },
          { label: "CO", value: "CO" },
          { label: "CT", value: "CT" },
          { label: "DE", value: "DE" },
          { label: "FL", value: "FL" },
          { label: "GA", value: "GA" },
          { label: "HI", value: "HI" },
          { label: "IA", value: "IA" },
          { label: "ID", value: "ID" },
          { label: "IL", value: "IL" },
          { label: "IN", value: "IN" },
          { label: "KS", value: "KS" },
          { label: "KY", value: "KY" },
          { label: "LA", value: "LA" },
          { label: "MA", value: "MA" },
          { label: "MD", value: "MD" },
          { label: "ME", value: "ME" },
          { label: "MI", value: "MI" },
          { label: "MN", value: "MN" },
          { label: "MO", value: "MO" },
          { label: "MS", value: "MS" },
          { label: "MT", value: "MT" },
          { label: "NC", value: "NC" },
          { label: "ND", value: "ND" },
          { label: "NE", value: "NE" },
          { label: "NH", value: "NH" },
          { label: "NJ", value: "NJ" },
          { label: "NM", value: "NM" },
          { label: "NV", value: "NV" },
          { label: "NY", value: "NY" },
          { label: "OH", value: "OH" },
          { label: "OK", value: "OK" },
          { label: "OR", value: "OR" },
          { label: "PA", value: "PA" },
          { label: "RI", value: "RI" },
          { label: "SC", value: "SC" },
          { label: "SD", value: "SD" },
          { label: "TN", value: "TN" },
          { label: "TX", value: "TX" },
          { label: "UT", value: "UT" },
          { label: "VA", value: "VA" },
          { label: "VT", value: "VT" },
          { label: "WA", value: "WA" },
          { label: "WI", value: "WI" },
          { label: "WV", value: "WV" },
          { label: "WY", value: "WY" },
        ],
      },
      age: {
        id: `${idPrefix ? `${idPrefix}_` : ""}age`,
        value: peopleAge,
        handleChange: setPeopleAge,
        error: peopleAgeError,
        handleError: setPeopleAgeError,
        options: [
          { label: "All Ages", value: "All Ages" },
          { label: "18-29", value: "18-29" },
          { label: "30-39", value: "30-39" },
          { label: "40-49", value: "40-49" },
          { label: "50-59", value: "50-59" },
          { label: "60-69", value: "60-69" },
          { label: "70-79", value: "70-79" },
          { label: "80-89", value: "80-89" },
          { label: "90-99", value: "90-99" },
          { label: "100-109", value: "100-109" },
        ],
      },
      submitPeopleSearch: () => {
        handleSearchPeople();
      },
    },
    property: {
      address: {
        id: `${idPrefix ? `${idPrefix}_` : ""}address`,
        value: propertyAddress,
        handleChange: setPropertyAddress,
        error: propertyAddressError,
        handleError: setPropertyAddressError,
      },
      submitPropertySearch: () => {
        handleSearchProperty();
      },
    },
    phoneInput: {
      phone: {
        id: `${idPrefix ? `${idPrefix}_` : ""}phone`,
        value: phoneInputValue,
        handleChange: setPhoneInputValue,
        error: phoneInputError,
        handleError: setPhoneInputError,
      },
      submitPhoneSearch: () => {
        handleSearchPhone();
      },
    },
    socialInput: {
      social: {
        id: `${idPrefix ? `${idPrefix}_` : ""}social`,
        value: socialInputValue,
        handleChange: setSocialInputValue,
        error: socialInputError,
        handleError: setSocialInputError,
      },
      submitSocialSearch: () => {
        handleSearchSocial();
      },
    },
    autoInput: {
      vin: {
        id: `${idPrefix ? `${idPrefix}_` : ""}auto`,
        value: autoInputValue,
        handleChange: setAutoInputValue,
        error: autoInputError,
        handleError: setAutoInputError,
      },
      submitAutoSearch: () => {
        handleSearchAuto();
      },
    },
    neighborhood: {
      address: {
        id: `${idPrefix ? `${idPrefix}_` : ""}neighborhood-address`,
        value: neighborhoodAddress,
        handleChange: setNeighborhoodAddress,
        error: neighborhoodAddressError,
        handleError: setNeighborhoodAddressError,
      },
      submitNeighborhoodSearch: () => {
        handleSearchNeighborhood();
      },
      name: {
        id: `${idPrefix ? `${idPrefix}_` : ""}neighborhood-name`,
        value: neighborhoodName,
        handleChange: setNeighborhoodName,
        error: neighborhoodNameError,
        handleError: setNeighborhoodNameError,
      },
      submitNeighborhoodNameSearch: () => {
        handleNeighborhoodNameSearch();
      },
      neighborhoodSearchType: neighborhoodSearchType,
      handleNeighborhoodSearchType: setNeighborhoodSearchType,
      showRadioInput:
        modifiers.neighborhoodModifactions &&
        modifiers.neighborhoodModifactions.showNeighborhoodRadioInput === false
          ? false
          : true,
    },
    ...modifiers,
  };
};
