import { store } from "../../store";
import { logoutWithRedirect } from "../authentication/authenticationSlice";

export function getSubscriptionDetails() {
  return new Promise((resolve, reject) => {
    fetch("/api/subscriptionDetails", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function downsellSubscription() {
  return new Promise((resolve, reject) => {
    fetch("/api/downsellSubscription", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        console.log("downsellSubscription data", data);
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function cancelSubscription() {
  return new Promise((resolve, reject) => {
    fetch("/api/cancelSubscription", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function addNote({ content }: { content: string }) {
  return new Promise((resolve, reject) => {
    fetch("/api/addNote", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ content }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
