import { store } from "../../store";
import { logoutWithRedirect } from "../authentication/authenticationSlice";

interface MonitoringInterface {
  hasChanges: boolean;
  version: string;
  versionZ?: string;
}

export interface PeopleMonitoringInterface extends MonitoringInterface {
  age: string;
  city: string;
  firstName: string;
  lastName: string;
  middleName: string;
  nameSuffix: string;
  reportToken: string;
  state: string;
  createdDate?: string;
  type: "people";
}

export interface NeighborhoodMonitoringInterface {
  type: "neighborhood";
  address: string;
  addressLine2: string;
  area: number;
  city: string;
  id: number;
  lastUpdate: string;
  latestAlert: string;
  latitude: string;
  longitude: string;
  nickname: string;
  notifications: string; // "weekly"
  radius: number;
  state: string;
  userId: number;
  zipCode: string;
  latestOffendersCount?: number;
  createdAt?: number;
}

export interface NeighborhoodSaveMonitoringInterface {
  address?: string;
  addressLine2?: string;
  addressSearch?: string;
  area?: number;
  city?: string;
  id?: number;
  latitude?: string;
  longitude?: string;
  nickname?: string;
  notifications?: string;
  radius?: number;
  state?: string;
  zipCode?: string;
  latestOffendersCount?: number;
}

export interface PropertyMonitoringInterface {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  nickname: string;
  state?: string;
  zip?: string;
  version?: number;
  versionZ?: number;
  hasChanges: boolean;
  type: "property";
  bedrooms?: number;
  totalBaths?: number;
  sqft?: string;
  lotAcres?: number;
  createdDate?: number;
}

export interface PropertyMonitoringParamsInterface {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  nickname: string;
  state?: string;
  zip?: string;
  version?: number;
  bedrooms?: number;
  totalBaths?: number;
  sqft?: string;
  lotAcres?: number;
}

/* People Monitoring */
export function getReportMonitoring() {
  return new Promise((resolve, reject) => {
    fetch("/api/getReportMonitoring", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data.result);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function toggleMonitoring(
  reportToken: string,
  version: string,
): Promise<MonitoringInterface> {
  return new Promise((resolve, reject) => {
    fetch("/api/toggleMonitoring", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ reportToken, version }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data.result);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

/* Neighborhood Monitoring */
export function getNeighborhoodWatchMonitoringAreas() {
  return new Promise((resolve, reject) => {
    fetch("/api/getNeighborhoodWatchMonitoringAreas", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data.result);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function saveNeighborhoodWatchMonitoringArea({
  address,
  addressLine2,
  addressSearch,
  area,
  city,
  id,
  latitude,
  longitude,
  nickname,
  radius,
  state,
  zipCode,
  latestOffendersCount,
}: NeighborhoodSaveMonitoringInterface) {
  return new Promise((resolve, reject) => {
    fetch("/api/saveNeighborhoodWatchMonitoringArea", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        address,
        addressLine2,
        addressSearch,
        area,
        city,
        id,
        latitude,
        longitude,
        nickname,
        radius,
        state,
        zipCode,
        latestOffendersCount,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function updateNeighborhoodWatchMonitoringArea({
  address,
  addressLine2,
  addressSearch,
  area,
  city,
  id,
  latitude,
  longitude,
  nickname,
  radius,
  state,
  zipCode,
  latestOffendersCount,
}: NeighborhoodSaveMonitoringInterface) {
  return new Promise((resolve, reject) => {
    fetch("/api/saveNeighborhoodWatchMonitoringArea", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        address,
        addressLine2,
        addressSearch,
        area,
        city,
        id,
        latitude,
        longitude,
        nickname,
        radius,
        state,
        zipCode,
        latestOffendersCount,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteNeighborhoodWatchMonitoringArea(id: number) {
  return new Promise((resolve, reject) => {
    fetch("/api/deleteNeighborhoodWatchMonitoringArea", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ id }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data.id);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

/* Property Monitoring */
export function getPropertyMonitoring() {
  return new Promise((resolve, reject) => {
    fetch("/api/getPropertyMonitoring", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data.result);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function togglePropertyMonitoring({
  addressLine1,
  addressLine2,
  city,
  nickname,
  state,
  zip,
  version,
  bedrooms,
  totalBaths,
  sqft,
  lotAcres,
}: PropertyMonitoringParamsInterface) {
  return new Promise((resolve, reject) => {
    fetch("/api/togglePropertyMonitoring", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        addressLine1,
        addressLine2,
        city,
        nickname,
        state,
        zip,
        version,
        bedrooms,
        totalBaths,
        sqft,
        lotAcres,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function updatePropertyMonitoring({
  addressLine1,
  addressLine2,
  city,
  nickname,
  state,
  zip,
}: PropertyMonitoringParamsInterface) {
  return new Promise((resolve, reject) => {
    fetch("/api/updatePropertyMonitoring", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        addressLine1,
        addressLine2,
        city,
        nickname,
        state,
        zip,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
