import React, { useState } from "react";
import {
  Sheet,
  Container,
  Padding,
  Layout,
  Spotlight,
  MultiSearch,
  Tab,
} from "@kidslivesafe/sc-react";
import { useAppDispatch } from "../../../hooks/hooks";

import { useMultiSearchProps } from "../../../hooks/multiSearchPropsHook";
import { setTab } from "../../../features/multisearch/multisearchSlice";
import {
  Header,
  SubHeader,
  SearchType,
  NoResultText,
  ShowMultiSearch,
} from "./NoResultsTypes";
import { MultiSearchProps } from "@kidslivesafe/sc-react/lib/molecules/MultiSearch/MultiSearch";

const NoResults: React.FC<{
  searchType: SearchType;
  header: Header;
  subHeader: SubHeader;
  showMultiSearch?: ShowMultiSearch;
  noResultText?: NoResultText;
  multiSearchProps?: MultiSearchProps;
  soloTab?: boolean;
  altSearchType?: "neighborhood";
}> = ({
  searchType,
  header,
  subHeader,
  noResultText,
  showMultiSearch = true,
  multiSearchProps,
  soloTab,
  altSearchType,
}) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(searchType);

  let passedMultiSearchProps = multiSearchProps;

  const defaultMultiSearchProps = useMultiSearchProps(
    {
      footer: false,
      resetInput: true,
      buttonText: "Try a New Search",
      openTab: activeTab,
      tabs: soloTab ? [searchType] : ["property", "people", "phone", "social"],
      handleSetTab: (tab: Tab | undefined) => {
        let validTab = tab ?? "property";
        // TODO: remove this once we purge auto completely, also switch above to const
        if (validTab === "vehicle") {
          validTab = "auto";
        }
        setActiveTab(validTab);
        dispatch(setTab(validTab));
      },
      neighborhoodModifactions: {
        showNeighborhoodRadioInput: false,
        setDefaultNeighborhoodSearchType:
          altSearchType === "neighborhood" ? "nameSearch" : "locationSearch",
      },
    },
    "no-results",
  );

  if (!passedMultiSearchProps) {
    passedMultiSearchProps = { ...defaultMultiSearchProps, openTab: activeTab };
  }

  return (
    <Sheet>
      <Container>
        <Padding top="lg" left="md" right="md" bottom="lg">
          {header}
          {noResultText ? noResultText : null}
          <Padding top="lg">{subHeader}</Padding>
        </Padding>
        {showMultiSearch ? (
          <Padding left="md" right="md" bottom="xl">
            <Layout
              type="flex"
              flexProps={{
                gap: "lg",
                alignItems: "center",
                direction: "column",
              }}
            >
              <Spotlight passThroughClasses="activity-history__spotlight">
                <MultiSearch {...passedMultiSearchProps} />
              </Spotlight>
            </Layout>
          </Padding>
        ) : null}
      </Container>
    </Sheet>
  );
};

export default NoResults;
